import React, { useEffect, Fragment, useState } from 'react'
import logo from "../../assets/media/logo.png";
import iconAppForm from "../../assets/media/icon-app-form.svg";
import iconApp from "../../assets/media/icon-application.svg";
import iconCertification from "../../assets/media/icon-certification.svg";
import iconDashboard from "../../assets/media/icon-dashboard.svg";
import iconDiscrepancies from "../../assets/media/icon-discrepancies.svg";
import iconDraft from "../../assets/media/icon-draft.svg";
import iconQuota from "../../assets/media/icon-quota.svg";
import iconRenewel from "../../assets/media/icon-renewel.svg";
import iconFeedback from "../../assets/media/icon-feedback.svg";
import iconEmployees from "../../assets/media/icon-employees.svg";
import iconLogout from "../../assets/media/icon-logout.svg";
import iconArrowUp from "../../assets/media/icon-arrow-up.svg";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../store/reducers/authSlice';
import { fetchCategories } from '../../store/reducers/categoriesSlice';
import { setActiveItem, setCreateForm, toggleApplicationDropdown, toggleFormDropdown } from '../../store/reducers/counterSlice';
import { verifyApplicationName } from '../../store/reducers/applicationSlice';
import Modal from '../modal/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';


const Navbar = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { formDropdown, applicationDropdown } = useSelector((state) => state.counter)
    const categories = useSelector((state) => state.categories);
    const activeItem = useSelector((state) => state.counter.activeItem)
    const authToken = user.access_token;
    const location = useLocation();
    const navigate = useNavigate();

    const close = () => setShowModal(false)
    const show = () => setShowModal(true)
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        dispatch(fetchCategories({ accessToken: authToken }));
    }, [authToken, dispatch]);
    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            category:'',
            applicationName: '',
            pageName: '',
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Category is required'),
            pageName: Yup.string().required('Page Name is required'),
            applicationName: Yup.string().required('Application Name is required'),
        }),

        onSubmit: async (formValues) => {
            let response = true
            let payload = {
                accessToken: authToken,
                name: formValues.applicationName
            }
            console.log(formValues.category, 'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
            const result = await dispatch(verifyApplicationName(payload))
            if (result?.error) response = false

            if (response) {
                dispatch(setCreateForm({ category: formValues.category, applicationName: formValues.applicationName }))

                let payload = {
                    pageName: formValues.pageName,
                    json_data: null
                }

                navigate(`/create-forms`, {
                    state: payload
                })
            }
            close()
        },
    });

    const customHandleChange = (event) => {
        const { name, value } = event.target;
        console.log(`Changing ${name} to ${value}`);
        handleChange(event);
    };
    return (
        <Fragment>
            <nav className="navbar">
                <div className='navHead'>
                    <Link to={'/'}><img src={logo} className='logo' alt='' /></Link>
                    <div style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
                        <ul >
                            <li className={`${location.pathname === '/dashboard' ? 'active' : ''}`}><a onClick={() => {
                                // dispatch(setActiveItem('Dashboard'))
                                navigate(`/dashboard`)
                            }}>
                                <img src={iconDashboard} alt='' />
                                <span style={{ cursor: 'pointer' }}>Dashboard</span>
                            </a>
                            </li>

                            {/* <li className={`${location.pathname === '/categories' ? 'active' : ''}`}>
                                <a onClick={() => {
                                    navigate(`/categories`)
                                }} >
                                    <img src={iconAppForm} alt='' /><span style={{ cursor: 'pointer' }}>Categories</span>
                                </a>
                            </li> */}

                            <li style={{ cursor: 'pointer' }} className={`${location.pathname === '/create-forms' ? 'active' : ''}`}><a onClick={() => {
                                // dispatch(setActiveItem('Create Forms'))
                                // navigate(`/create-forms`)
                                show();
                            }} ><img src={iconCertification} alt='' /><span>Create Forms</span></a></li>
                            <li className='collapsed'>
                                <div className='navBlock ' style={{ cursor: 'pointer' }} onClick={() => { dispatch(toggleFormDropdown()) }}>
                                    <a to={'/forms'} onClick={() => {
                                        // dispatch(setActiveItem(categories?.data[0].name));
                                        // navigate("/forms?item=1", {
                                        //     state: {
                                        //         category: ""
                                        //     }
                                        // })
                                        navigate(`/forms?item=1`, {
                                            state: {
                                                category: ""
                                            }
                                        })
                                    }}>
                                        <img src={iconAppForm} alt='' />
                                        <span style={{ cursor: 'pointer' }}>Application Forms {formDropdown}</span>
                                    </a>
                                    <img className='iconToggle' src={iconArrowUp} alt='' />
                                </div>
                                <ul style={{ display: formDropdown ? 'none' : '', cursor: 'pointer' }}>
                                    {categories?.data?.map((item, index) => (
                                        <li key={index} className={`${+location.search.split("=")[1] === item.id && location.pathname.includes("forms") ? 'active' : ''}`}>
                                            <a onClick={() => {
                                                // dispatch(setActiveItem(item.name));
                                                navigate(`/forms?item=${item.id}`, {
                                                    state: {
                                                        category: item.name
                                                    }
                                                })
                                            }}>
                                                <span>{item.name}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                                {/* <ul>
                            <li>
                                <Link to={'/dashboard'}>
                                    <span>Gaming Licence & Tax</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/dashboard'}>
                                    <span>Junket Operator</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/dashboard'}>
                                    <span>Gaming House</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/dashboard'}>
                                    <span>Supplier</span>
                                </Link>
                            </li>
                        </ul> */}
                            </li>
                            <li className='collapsed'>
                                <div className='navBlock' onClick={() => { dispatch(toggleApplicationDropdown()) }}>
                                    <a to={'/in-process-applications'} onClick={() => {
                                        navigate(`/in-process-applications`)
                                        // dispatch(setActiveItem('New Applications'))
                                    }}>
                                        <img src={iconApp} alt='' />
                                        <span style={{ cursor: 'pointer' }}>Applications</span>
                                    </a>
                                    <img className='iconToggle' src={iconArrowUp} alt='' />
                                </div>
                                <ul style={{ display: applicationDropdown ? 'none' : '' }}>
                                    <li className={`${location.pathname === '/in-process-applications' ? 'active' : ''}`}>
                                        <Link to={'/in-process-applications'}
                                        //  onClick={() => {
                                        //     dispatch(setActiveItem('New Applications'))
                                        // }}
                                        >
                                            <span style={{ cursor: 'pointer' }}>New Applications</span>
                                        </Link>
                                    </li>
                                    <li className={`${location.pathname === '/pending-applications' ? 'active' : ''}`}>
                                        <Link to={'/pending-applications'}
                                        // onClick={() => {
                                        //     dispatch(setActiveItem('Pending Form DW'))
                                        // }}
                                        >
                                            <span>Pending Form DW</span>
                                        </Link>
                                    </li>
                                    <li className={`${location.pathname === '/rejected-applications' ? 'active' : ''}`}>
                                        <Link to={'/rejected-applications'}
                                        // onClick={() => {
                                        //     dispatch(setActiveItem('Rejected'))
                                        // }}
                                        >
                                            <span>Rejected</span>
                                        </Link>
                                    </li>
                                    <li className={`${location.pathname === '/approved-applications' ? 'active' : ''}`}>
                                        <Link to={'/approved-applications'}
                                        // onClick={() => {
                                        //     dispatch(setActiveItem('Approved'))
                                        // }}
                                        >
                                            <span>Approved</span>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`${location.pathname === '/renewals' ? 'active' : ''}`}><Link to={'/renewals'}
                            ><img src={iconRenewel} alt='' /><span>Renewal</span></Link></li>
                            <li className={`${location.pathname === '/discrepancies' ? 'active' : ''}`}><Link to={'/discrepancies'}
                            ><img src={iconDiscrepancies} alt='' /><span>Discrepancies</span></Link></li>
                            <li className={`${location.pathname === '/certifications' ? 'active' : ''}`}><Link to={'/certifications'}
                            ><img src={iconCertification} alt='' /><span>Certifications</span></Link></li>
                            <li className={`${location.pathname === '/employees' ? 'active' : ''}`}><Link to={'/employees'}
                            ><img src={iconQuota} alt='' /><span>Registered Employees</span></Link></li>
                            <li className={`${location.pathname === '/compamies' ? 'active' : ''}`}><Link to={'/compamies'}
                            ><img src={iconQuota} alt='' /><span>Registered Companies</span></Link></li>
                            <li className={`${location.pathname === '/users' ? 'active' : ''}`}><Link to={'/users'}
                            ><img src={iconEmployees} alt='' /><span>Users</span></Link></li>
                            <li className={`${location.pathname === '/feedback' ? 'active' : ''}`}><Link to={'/feedback'}
                            ><img src={iconFeedback} alt='' /><span>Feedback</span></Link></li>
                        </ul>
                        <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} className='navFoot' onClick={() => {
                            dispatch(logoutUser());

                        }}>
                            <img className='' src={iconLogout} alt='' />
                            <p className='mx-2 mt-3'>Logout</p>
                        </div>
                    </div>

                </div>
            </nav>
            <Modal show={showModal} colWidth={6}>
                <div className='d-flex justify-content-center'>
                    <h2>Create Page</h2>
                </div>
                <div>
                    <form onSubmit={handleSubmit} className="">
                        <div>
                            <div className='d-flex align-items-center w-100'>
                                <p className='mb-0' style={{ width: "20%" }}>Category</p>
                                {categories.data.length > 0 && (
                                    <select
                                        style={{
                                            flex: 1,
                                            paddingTop: "8px",
                                            paddingBottom: "8px",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                        }}
                                        name='category'
                                        onChange={handleChange}
                                        value={values['category']}
                                    >
                                        <option >Select Category</option>
                                        {categories.data.map(category => (
                                            <option key={category?.id} value={category?.name}>{category?.name}</option>
                                        ))}
                                    </select>
                                )}
                            </div>
                            <div>
                                {touched['category'] && errors['category'] && <div className="error-message">{errors['category']}</div>}
                            </div>
                        </div>
                        <div className='my-4'>
                            <div className='d-flex align-items-center w-100'>
                                <p className='mb-0' style={{ width: "20%" }}>Page Name</p>
                                <input
                                    style={{ flex: 1 }}
                                    placeholder='Page Name'
                                    name='pageName'
                                    onChange={handleChange}
                                    value={values['pageName']}
                                />
                            </div>
                            <div>
                                {touched['pageName'] && errors['pageName'] && <div className="error-message">{errors['pageName']}</div>}
                            </div>
                        </div>
                        <div>
                            <div className='d-flex align-items-center w-100'>
                                <p className='mb-0' style={{ width: "20%" }}>Application Name</p>
                                <input
                                    style={{ flex: 1 }}
                                    placeholder='Application Name'
                                    name='applicationName'
                                    onChange={handleChange}
                                    value={values['applicationName']}
                                />
                            </div>
                            <div>
                                {touched['applicationName'] && errors['applicationName'] && <div className="error-message">{errors['applicationName']}</div>}
                            </div>
                        </div>
                        <div className='d-flex'>
                            <button type="submit" className="btn btn-primary mt-5" style={{ flex: 1, marginRight: 10 }}>Submit</button>
                            <button type="button" className="btn btn-danger mt-5" onClick={close}>Cancel</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Fragment>
    )
}
export default Navbar