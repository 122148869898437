// File: CertificateUploadModal.js

import React, { useState,useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CertificateUploadModal = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1); // Add 1 year
        return date;
    });


    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const selectedFile = event.target.files[0]; // Get the first selected file
            console.log('Selected file:', selectedFile); // Log the selected file for debugging
            props.setSelectedCertificateFile(selectedFile); // Set the file to state
        }
    };
    // Function to handle upload
    const handleUpload = () => {
        props.handleFileUpload( startDate, endDate); // Pass selected file and dates to parent component
        // handleClose(); // Close the modal after upload
    };

    return (
        <Modal show={props.showModal} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Certificate & Renewal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* File Upload */}
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept=".jpg,.jpeg,.png" // Limit to file types you want to allow
                />
                <label htmlFor="fileInput" style={{ cursor: 'pointer', backgroundColor: '#ddd', padding: '10px', borderRadius: '4px' }}>
                    Upload Certificate
                </label>
                <span style={{ marginLeft: '10px' }}>
                    {props.selectedCertificateFile ? props.selectedCertificateFile.name : "No file selected"}
                </span>

                {/* First Date Picker: Start Date */}
                <div className="mt-3">
                    <label>Issue Date:</label>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select Issue Date"
                    />
                </div>

                {/* Second Date Picker: End Date */}
                <div className="mt-3">
                    <label>Due Date:</label>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select Due Date"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleUpload}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CertificateUploadModal;
