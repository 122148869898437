import React, { useEffect, useState } from "react";
import iconNotification from "../../assets/media/icon-notification.svg";
import iconSearch from "../../assets/media/icon-search.svg";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, getallUser } from "../../store/reducers/userSlice";
import userImagePlaceholder from "../../assets/media/avatar2.jpg";

const Header = ({ handleSearchInputChange }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const getUserData = useSelector((state) => state.usersdata.getuserdata);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read_status
  );

  useEffect(() => {
    dispatch(
      getUser({
        userId: user.user.id,
        userData: { accessToken: user.access_token },
      })
    );
    dispatch(getallUser({ authToken: user.access_token }));
  }, [dispatch, user, notifications]);
  return (
    <header className="header">
      <div className="searchForm">
        <input
          type="text"
          onChange={handleSearchInputChange}
          placeholder="input search text"
        />
        <button>
          <img src={iconSearch} alt="" />
        </button>
      </div>
      <div className="notification">
        <Link to={"/notification"}>
          <img src={iconNotification} alt="" />
          <span>{unreadNotifications.length}</span>
        </Link>
      </div>
      <Link to={"/profile"}>
        <img
          className="avatar"
          src={getUserData.image || userImagePlaceholder}
          alt=""
        />
      </Link>
    </header>
  );
};
export default Header;
