import React from "react";

import Modal from "../modal/Modal";

const ConfirmationModal = ({
  show,
  handleClose,
  title,
  description,
  onConfirm,
}) => {
  return (
    <Modal
      bodyStyle={{ borderRadius: "10px" }}
      colWidth={5}
      show={show}
      handleClose={handleClose}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
        }}
        className="modal-header"
      >
        <h5 className="modal-title">{title}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
        ></button>
      </div>
      <div style={{ marginBottom: "10px" }} className="modal-body">
        <p>{description}</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
        }}
        className="modal-footer"
      >
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary btn-danger"
          onClick={onConfirm}
        >
          Confirm
        </button>
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
