// reducers/applicationSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  fetchLoading: false,
  fetchError: null,
  message: null,
  data: [],
  currentApp: {},
};

export const updateApplication = createAsyncThunk(
  "updateApplication",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };

      const payload = {};
      if (data?.state) payload.state_id = data?.state;
      if (data?.json_data) payload.json_data = data?.json_data;
      if (data?.dueDate) payload.dueDate = data.dueDate;
      if (data?.descripencie) payload.descripencie = data.descripencie;
      if (data?.htmlForm) payload.htmlForm = data?.htmlForm;

      const response = await API.put(
        `/applications/${data?.id}`,
        payload,
        config
      );
      showSuccess("Your application has been updated!");
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);

export const fetchApplications = createAsyncThunk(
  "fetchApplications",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/applications", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);
export const fetchApplicationWithId = createAsyncThunk(
  "fetchApplicationWithId",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get(`/applications/${data?.id}`, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load applications");
    }
  }
);
export const postForm = createAsyncThunk("postForms", async (data) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };

    const response = await API.post("/forms", { ...data?.data }, config);
    showSuccess("Your application has been created!");
    return response.data;
  } catch (error) {
    throw new Error("Failed to submit form");
  }
});
export const updateForm = createAsyncThunk("updateForm", async (data, id) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };
    const response = await API.put(
      `/forms/${data?.id}`,
      { ...data?.data },
      config
    );
    showSuccess("Your application has been updated!");

    return response.data;
  } catch (error) {
    throw new Error("Failed to submit form");
  }
});
export const verifyApplicationName = createAsyncThunk(
  "verifyApplicationName",
  async (data) => {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
        Authorization: `${data.accessToken}`,
      },
    };
    return API.post("/forms/verify-application-name", { ...data }, config);
  }
);

export const addCertificateAndRenewal = async (data) => {
  try {
    // Prepare form data
    const formData = new FormData();

    // Append issue_date, due_date, and other data
    formData.append("issue_date", data.issue_date);
    formData.append("due_date", data.due_date);
    formData.append("user_id", data.user_id);

    // Append file if available
    if (data.file) {
      formData.append("file", data.file); // 'file' should match the backend file input name
    }

    // Call the API
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}applications/${data.id}/certificate&renewal`,
      {
        method: "PUT",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `${data.authToken}`,
        },
        body: formData, // Attach formData to the request body
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update certificate and renewal");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error updating certificate and renewal:", error);
  }
};

export const getAllCertificates = async (data) => {
  try {
    // Call the API
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}applications/users/certificates`,
      {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `${data.authToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to Get Certificates");
    }

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error("Error Getting data:", error);
  }
};

export const fetchV2Applications = async (data) => {
  try {
    const { accessToken, ...queryParams } = data;

    // Filter out undefined or null query parameters
    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null)
    );

    // Prepare the query parameters string if any valid query parameters are available
    const queryString = new URLSearchParams(filteredParams).toString();
    const url = queryString ? `${process.env.REACT_APP_BACKEND_URL}applications/v2/all?${queryString}` : `${process.env.REACT_APP_BACKEND_URL}applications/v2/all`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken, // Add 'Bearer' if required by your backend
      },
    });

    // Check for successful response
    if (!response.ok) {
      throw new Error("Failed to load applications");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching applications:", error);
    throw new Error("Failed to load applications");
  }
};

const applicationSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApplications.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.data = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplications.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
      })
      .addCase(fetchApplicationWithId.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchApplicationWithId.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.currentApp = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchApplicationWithId.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.currentApp = null;
        state.message = null;
      });
  },
});

export default applicationSlice.reducer;
