import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

export const fetchFormMandatoryFields = createAsyncThunk(
  "mandatoryFields/fetchFormMandatoryFields",
  async ({ user_id, form_id, company_id, authToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        params: { user_id, form_id, company_id },
      };
      const response = await API.get("/form-mandatory-fields", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to fetch mandatory fields"
      );
    }
  }
);

export const createFormMandatoryField = createAsyncThunk(
  "mandatoryFields/createFormMandatoryField",
  async ({ data, authToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.post("/formmandatoryfields", data, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to create mandatory field"
      );
    }
  }
);

export const updateFormMandatoryField = createAsyncThunk(
  "mandatoryFields/updateFormMandatoryField",
  async ({ id, data, authToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      const response = await API.put(
        `/form-mandatory-fields/${id}`,
        data,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to update mandatory field"
      );
    }
  }
);

export const deleteFormMandatoryField = createAsyncThunk(
  "mandatoryFields/deleteFormMandatoryField",
  async ({ id, authToken }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      };
      await API.delete(`/form-mandatory-fields/${id}`, config);
      return id;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "Failed to delete mandatory field"
      );
    }
  }
);

const initialState = {
  fields: [],
  loading: false,
  error: null,
};

const mandatoryFieldsSlice = createSlice({
  name: "mandatoryFields",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormMandatoryFields.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFormMandatoryFields.fulfilled, (state, action) => {
        state.loading = false;
        state.fields = action.payload;
        state.error = null;
      })
      .addCase(fetchFormMandatoryFields.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createFormMandatoryField.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFormMandatoryField.fulfilled, (state, action) => {
        state.loading = false;
        state.fields.push(action.payload);
        state.error = null;
      })
      .addCase(createFormMandatoryField.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateFormMandatoryField.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFormMandatoryField.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.fields.findIndex(
          (field) => field.id === action.payload.id
        );
        if (index !== -1) {
          state.fields[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(updateFormMandatoryField.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteFormMandatoryField.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFormMandatoryField.fulfilled, (state, action) => {
        state.loading = false;
        state.fields = state.fields.filter(
          (field) => field.id !== action.payload
        );
        state.error = null;
      })
      .addCase(deleteFormMandatoryField.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default mandatoryFieldsSlice.reducer;
