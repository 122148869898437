import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar01 from "../../../assets/media/avatar-01.png";

import Header from "../../header";
import Navbar from "../../navbar";

import moment from "moment";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconFileDelete from "../../../assets/media/icon-file-delete.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconFileEdit from "../../../assets/media/icon-file-edit.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import { ReactComponent as ArrowLeft } from "../../../assets/svgicons/ArrowLeft.svg";
import {
  fetchEmployeeApplications,
  fetchEmployeeById,
} from "../../../store/reducers/employeeSlice.js";
import "./styles.css";
import Pagination from "../dashboard/appPagination.js";
import userImagePlaceholder from "../../../assets/media/avatar2.jpg";
export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = { year: "numeric", month: "long", day: "2-digit" };
  return date.toLocaleDateString(undefined, options);
};

const EmployeeDetails = () => {
  const initialState = {
    All: true,
    // 'Pending': false,
    Approved: false,
    Rejected: false,
    "Pending From DW": false,
    "In Process": false,
    // Discrepancies: false,
    // Drafts: false,
    // Renewal: false,
  };

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState(initialState);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const employee = useSelector((state) => state.employee);
  const {
    applications: applicationRecords,
    applicationsLoading: applicationsLoader,
  } = useSelector((state) => state.employee);

  const users = employee?.user;
  const isLoading = employee?.fetchLoading;
  const authToken = user.access_token;
  const { id } = useParams();
  const navigate = useNavigate();

  function CheckboxFilter({ checkboxes, setCheckboxes, setSearchTerm }) {
    const handleCheckboxChange = (event) => {
      const { value, checked } = event.target;
      if (value === "All") {
        setCheckboxes((prevCheckboxes) => {
          const newCheckboxes = { ...prevCheckboxes };
          for (const key in newCheckboxes) {
            newCheckboxes[key] = checked;
          }
          return newCheckboxes;
        });
        setSearchTerm("");
      } else {
        setCheckboxes((prevCheckboxes) => ({
          ...prevCheckboxes,
          [value]: checked,
        }));
        setSearchTerm("");
      }
    };

    return (
      <div className="boardFilter">
        {Object.keys(checkboxes).map((checkboxValue) => (
          <div className="form-check form-check-inline" key={checkboxValue}>
            <input
              className="form-check-input"
              type="checkbox"
              value={checkboxValue}
              checked={checkboxes[checkboxValue]}
              onChange={handleCheckboxChange}
            />
            <label className="form-check-label">{checkboxValue}</label>
          </div>
        ))}
      </div>
    );
  }
  const stateButtonMap = {
    in_process: {
      className: "btnProcess",
      text: "In Process",
      state: "in_process",
    },
    pending: { className: "btnPending", text: "Pending", state: "pending" },
    approved: { className: "btnApproved", text: "Approved", state: "approved" },
    rejected: { className: "btnRejected", text: "Rejected", state: "rejected" },
    discrepancies: {
      className: "btnDiscrepancies",
      text: "Discrepancies",
      state: "discrepancies",
    },
    pending_from_dw: {
      className: "btnPendingFromDW",
      text: "Pending From DW",
      state: "pending_from_dw",
    },
    drafts: { className: "btnDrafts", text: "Drafts", state: "drafts" },
    renewal: { className: "btnRenewal", text: "Renewal", state: "renewal" },
  };

  const handleInputChange = (event) => {
    filtered(event.target.value);
    setSearchTerm(event.target.value);
  };

  const filtered = (search = "") => {
    const filteredData = applicationRecords
      .filter((item) => {
        let stateInfo = stateButtonMap[item.state];
        return (
          (stateInfo && checkboxes[stateInfo.text]) ||
          checkboxes["All"] === true ||
          typeof stateInfo === "undefined"
        );
      })
      .map((item) => ({
        ...item,
        created_at: formatDate(item.created_at),
        updated_at: formatDate(item.updated_at),
        stateText: stateButtonMap[item.state].text,
      }))
      .filter((item) => {
        for (const key in item) {
          if (
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(search.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });

    setData(filteredData);
    return filteredData;
  };
  const getTotalPages = () => {
    if (Number.isInteger(data?.length / 10)) return data?.length / 10;
    return Math.floor(data?.length / 10) + 1;
  };
  useEffect(() => {
    dispatch(fetchEmployeeById({ accessToken: authToken, userId: id }));
    dispatch(fetchEmployeeApplications({ accessToken: authToken, userId: id }));
  }, [authToken, dispatch]);

  return (
    <>
      <div>
        <Navbar />
        <Header />
        {isLoading ? (
          <div className="wrapper emp-details-wrapper">
            <div className="d-flex justify-content-center my-4">
              <div
                class=" text-center items-center spinner-border text-primary"
                style={{ width: "4rem", height: "4rem" }}
                role="status"
              ></div>
            </div>
          </div>
        ) : (
          <div className="wrapper emp-details-wrapper">
            <div className="heading-section">
              <div className="heading-wrapper">
                <ArrowLeft className="c-p" onClick={() => navigate(-1)} />
                <p>Registered Employee Details</p>
              </div>
              <div className="heading-wrapper" style={{ gap: "20px" }}>
                <span className="remove-btn c-p">Remove</span>
                <Button className="download-btn">Download PDF</Button>
              </div>
            </div>
            <div className="user-details-section">
              <div className="user-name">
                <img src={users.image || userImagePlaceholder} alt="user" />
                <div className="name-phone">
                  <p>{users.full_name}</p>
                  <p>{users.national_id_number}</p>
                </div>
              </div>
              <div className="user-details">
                <div className="user-detail-item">
                  <p>Company Name</p>
                  <p>{users?.company?.company_name}</p>
                </div>
                <div className="user-detail-item">
                  <p>Employee Id</p>
                  <p>{users.id}</p>
                </div>
                <div className="user-detail-item">
                  <p>Phone No.</p>
                  <p>{users.phone}</p>
                </div>
                <div className="user-detail-item">
                  <p>Registration Date</p>
                  <p>{moment(users.created_at).format("MMMM D, YYYY")}</p>
                </div>
              </div>
            </div>
            <div className="previous-companies-wrapper">
              <p className="previous-heading">Previous Companies</p>
              <table className="">
                <thead className="table-header">
                  <tr>
                    <th>Company Name</th>
                    <th>Employee Id</th>
                    <th>From</th>
                    <th>To</th>
                  </tr>
                </thead>
                <tbody>
                  {users?.past_experience?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.company_name}</td>
                      <td>{users.id}</td>
                      <td>{moment(item.start_date).format("MMMM D, YYYY")}</td>
                      <td>
                        {item.end_date == "Invalid date"
                          ? moment(new Date()).format("MMMM D, YYYY")
                          : moment(item.end_date).format("MMMM D, YYYY")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="boxAppBoard boxWhite">
              <div className="boardHeader">
                <CheckboxFilter
                  setSearchTerm={setSearchTerm}
                  checkboxes={checkboxes}
                  setCheckboxes={setCheckboxes}
                />
                <div className="boardHeadRight">
                  <div className="searchForm">
                    <input
                      type="text"
                      placeholder="input search text"
                      onChange={handleInputChange}
                      value={searchTerm}
                    />
                    <button>
                      <img src={iconSearch} alt="" />
                    </button>
                  </div>
                  <div className="datePicker"></div>
                </div>
              </div>
              <div className="boardContent">
                <div className="boardThead">
                  <div className="boardCol w-85">S.NO.</div>
                  <div className="boardCol w-165">Application No.</div>
                  <div className="boardCol w-adj1">Application Name</div>
                  <div className="boardCol w-165">Submitted Date</div>
                  <div className="boardCol w-165">Registered Date</div>
                  <div className="boardCol w-165">Status</div>
                  <div className="boardCol w-165">Attachments</div>
                  <div className="boardCol w-110"></div>
                </div>
                {data?.map((application, index) => {
                  let stateInfo = stateButtonMap[application.state];
                  return (
                    <Fragment key={index}>
                      <div
                        className={`boardTrow ${
                          (currentPage === 1
                            ? currentPage
                            : (currentPage - 1) * 10 + 1) <=
                            index + 1 &&
                          index < (currentPage === 1 ? 10 : currentPage * 10)
                            ? ""
                            : "d-none"
                        } `}
                      >
                        <div className="boardCol w-85">{index + 1}</div>
                        <div className="boardCol w-165">GB-4939907</div>
                        <div className="boardCol w-adj1">
                          <div className="boardTitle">
                            <strong>{application.name}</strong>
                            <span>{application.form}</span>
                          </div>
                        </div>
                        <div className="boardCol w-165">
                          {formatDate(application.updated_at)}
                        </div>
                        <div className="boardCol w-165">
                          {formatDate(application.created_at)}
                        </div>
                        <div className="boardCol w-165">
                          {" "}
                          <Link
                            className={`boardButton ${stateInfo.className}`}
                            to={"/"}
                          >
                            {stateInfo.text}
                          </Link>
                        </div>
                        <div className="boardCol w-165">
                          <div className="boardDownload">
                            <Link to={"/"}>
                              <img src={iconFilePdf} alt="" />
                            </Link>
                            <Link to={"/"}>
                              <img src={iconFileDoc} alt="" />
                            </Link>
                            <Link to={"/"}>
                              <img src={iconFileImage} alt="" />
                            </Link>
                          </div>
                        </div>
                        <div className="boardCol w-110">
                          <div className="boardActions">
                            {index % 2 === 0 ? (
                              <Link to={"/"}>
                                <img src={iconFileDelete} alt="" />
                              </Link>
                            ) : (
                              <Link to={"/"}>
                                <img src={iconFileEdit} alt="" />
                              </Link>
                            )}
                            <Link to={"/"}>
                              <img src={iconFileDownload} alt="" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={getTotalPages}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default EmployeeDetails;
