import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import iconLogo from "../../../assets/media/icon-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../navbar";
import Header from "../../header";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveItem,
  setCreateForm,
} from "../../../store/reducers/counterSlice";
import { fetchForms, deleteForms } from "../../../store/reducers/formSlice";
import Modal from "../../modal/Modal";
import classes from "./style.module.css";
import uploadIcon from "../../../assets/svgicons/upload.svg";
import closeIcon from "../../../assets/svgicons/close.svg";
import { fetchApplications } from "../../../store/reducers/applicationSlice";
import { createuploadpdf } from "../../../store/reducers/uploadSlice";
import ConfirmationModal from "../../common/confirmationModal";

const AppFormsAdmin = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const accessToken = useSelector((state) => state.auth.user.access_token);
  const user = useSelector((state) => state.auth.user);

  const categories = useSelector((state) => state.categories);
  const activeItem = useSelector((state) => state.counter.activeItem);

  const forms = useSelector((state) => state.forms);
  const applications = useSelector((state) => state.applications);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchForms({ accessToken: accessToken }));
    dispatch(fetchApplications({ accessToken: accessToken }));
  }, [accessToken, dispatch]);
  const [showModal, setShowModal] = useState(false);

  const show = () => setShowModal(true);

  const close = () => setShowModal(false);

  const deleteForm = async (form) => {
    await dispatch(deleteForms({ accessToken: accessToken, id: form?.id }));
    // navigate('/dashboard');
  };
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      category: "",
      application_name: "",
      pdfFile: null,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Category is required"),
      application_name: Yup.string().required("Application Name is required"),
      pdfFile: Yup.mixed()
        .required("PDF file is required")
        .test("fileType", "Only PDF files are allowed", (value) => {
          return value && value.type === "application/pdf";
        }),
    }),
    onSubmit: async (formValues, { resetForm }) => {
      let filterapplicationame = applications.data.filter((item) => {
        return item.id === Number(formValues.application_name);
      });

      const reader = new FileReader();

      const formData = new FormData();
      formData.append("category_id", Number(formValues.category));
      formData.append("application_name", filterapplicationame[0].name);
      formData.append("application_id", Number(formValues.application_name));
      formData.append("company_id", user.user.company_id);
      formData.append("user_id", user.user.id);
      formData.append("accessToken", user.access_token);
      formData.append("pdfFile", formValues.pdfFile, formValues.pdfFile.name); // Append the file with name and type

      for (let pair of formData.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      dispatch(createuploadpdf(formData));
      resetForm();
      close();
    },
  });

  return (
    <>
      <div>
        <Navbar />
        <Header />
        <div className="wrapper">
          <div className="profHeader">
            <div className="profHeadLeft">
              <h2>Application Forms</h2>
            </div>
            <div className="profHeadRight">
              <a className="buttonPrimary" onClick={show}>
                Upload PDF Form
              </a>
            </div>
          </div>
          <div className="boxAppForms boxWhite">
            <ul>
              {categories?.data.map((item, index) => {
                return (
                  <li key={index}>
                    <a
                      className={`${
                        +location.search.split("=")[1] === item.id
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        // navigate(`/forms?item=${item.id}`)
                        navigate(`/forms?item=${item.id}`, {
                          state: {
                            category: item.name,
                          },
                        });
                      }}
                    >
                      {item.name}
                      <span>{item.formCount}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="divAppForms">
            <div className="rowAppForms">
              {!forms?.fetchLoading &&
                forms?.data?.map((form, i) => {
                  if (form.category === location?.state?.category) {
                    return (
                      <div className="colAppForm" key={i}>
                        <div className="AppFormWrap">
                          <b className="appBadge">
                            {form.is_published ? "Published" : "Draft"}
                          </b>
                          <img src={iconLogo} alt="" />
                          <h3>{form.name}</h3>
                          <p>{form.category}</p>
                          <div className="buttonGroup">
                            <a
                              className="AppFormButton"
                              onClick={(e) => {
                                console.log({ form });
                                dispatch(
                                  dispatch(
                                    setCreateForm({
                                      category: form.category,
                                      applicationName:
                                        form.name || form.applicationName,
                                      id: form.id,
                                      isSelected: true,
                                    })
                                  )
                                );
                                navigate("/create-forms", {
                                  state: { form, isEditidingForm: true },
                                });
                              }}
                            >
                              Edit Form
                            </a>
                            <a
                              className="AppFormButton2"
                              //   onClick={() => deleteForm(form)}
                              onClick={() => {
                                setShowDeleteModal(true);
                                setDeleteUserId(form);
                              }}
                            >
                              Delete Form
                            </a>
                            {/* <Link className='AppFormButton2' to={'/form-step-01'}>Delete Form</Link> */}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <ConfirmationModal
          show={showDeleteModal && deleteUserId}
          title={"Delete User"}
          description={"Are you sure you want to delete this user?"}
          handleClose={() => {
            setShowDeleteModal(false);
            setDeleteUserId(null);
          }}
          onConfirm={() => {
            deleteForm(deleteUserId);
            setShowDeleteModal(false);
            setDeleteUserId(null);
          }}
        />
      </div>
      <Modal show={showModal} colWidth={4}>
        <div className="">
          <p>Upload PDF Form</p>
          <form onSubmit={handleSubmit}>
            <div className="mb-3 d-flex flex-column">
              <label className={classes["form-label"]}>Category</label>

              <select
                style={{
                  flex: 1,
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                className={["form-class", classes["form-input"]].join(" ")}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete={"false"}
                id={"category"}
                name={"category"}
                value={values["category"]}
              >
                <option>Select Category</option>
                {categories.data.map((category) => (
                  <option key={category?.id} value={category?.id}>
                    {category?.name}
                  </option>
                ))}
              </select>
              {touched["category"] && errors["category"] && (
                <div className="error-message">{errors["category"]}</div>
              )}
            </div>
            <div className="mb-3 d-flex flex-column">
              <label className={classes["form-label"]}>Application Name</label>
              <select
                style={{
                  flex: 1,
                  paddingTop: "8px",
                  paddingBottom: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                className={["form-class", classes["form-input"]].join(" ")}
                value={values["application_name"]}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete={"false"}
                id={"application_name"}
                name={"application_name"}
              >
                <option>Select Application</option>
                {applications.data
                  .filter((app) =>
                    forms.data.find(
                      (form) =>
                        form.id === app.form_id &&
                        Number(form.category_id) ===
                          Number(values["category"] || categories.data[0].id)
                    )
                  )
                  .map((application) => (
                    <option key={application?.id} value={application?.id}>
                      {application?.name}
                    </option>
                  ))}
              </select>

              {touched["application_name"] && errors["application_name"] && (
                <div className="error-message">
                  {errors["application_name"]}
                </div>
              )}
            </div>

            <div className="mb-3 d-flex flex-column">
              <div className="d-flex align-items-center">
                <label
                  htmlFor="pdfFile"
                  className={[classes["upload-pdf-label"], "d-flex"].join(" ")}
                >
                  <span>
                    <img alt="Upload" src={uploadIcon} />
                  </span>
                  <span className="mx-2">Upload PDF</span>
                </label>
                <label className={classes["uploaded-pdf-label"]}>
                  {!!values["pdfFile"]
                    ? `[${
                        values["pdfFile"]?.name.substring(0, 5) + "..." + ".pdf"
                      }]`
                    : ""}
                </label>
                {!!values["pdfFile"] && (
                  <button
                    type="button"
                    className={classes["remove-pdf-button"]}
                    onClick={() => setFieldValue("pdfFile", null)}
                  >
                    <img alt="close" src={closeIcon} />
                  </button>
                )}
              </div>
              <input
                className="form-class"
                style={{
                  padding: 5,
                  borderRadius: "6px",
                  border: "1px solid var(--neutral-5, #D9D9D9)",
                  background: "var(--neutral-1, #FFF)",
                  display: "none",
                }}
                type="file"
                onChange={(event) => {
                  setFieldValue("pdfFile", event.currentTarget.files[0]);
                }}
                onBlur={handleBlur}
                autoComplete={"false"}
                id={"pdfFile"}
                name={"pdfFile"}
                accept="application/pdf"
              />
              {touched["pdfFile"] && errors["pdfFile"] && (
                <div className="error-message">{errors["pdfFile"]}</div>
              )}
            </div>

            <div className="d-flex justify-content-between">
              <div className="">
                <button type="submit" className="create-form-button">
                  Create
                </button>
              </div>
              <div className="gb-form-footer">
                <button
                  type="button"
                  onClick={close}
                  className="create-form-cancel-button"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default AppFormsAdmin;
