// reducers/employeeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  createLoading: false,
  fetchLoading: false,
  applicationsLoading: false,
  createError: null,
  fetchError: null,
  message: null,
  users: [],
  user: {},
  applications: [],
};

export const addEmployee = createAsyncThunk(
  "employee/addEmployee",
  async (data) => {
    try {
      console.log(data);
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          Authorization: `${data.accessToken}`,
          "Content-Type": "application/json",
        },
      };

      const response = await API.post("/users", data.userData, config);
      showSuccess("Employee created!");
      return response.data;
    } catch (error) {
      console.log({ error });
      throw new Error(
        error?.response?.data?.message || "Failed to add employee"
      );
    }
  }
);

export const adduser = createAsyncThunk("employee/adduser", async (data) => {
  try {
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "ngrok-skip-browser-warning": "69420",
        Authorization: `${data.accessToken}`,
        "Content-Type": "application/json",
      },
    };

    const response = await API.post("/users", data.userData, config);
    showSuccess("User created!");
    return response.data;
  } catch (error) {
    console.log({ error });
    throw new Error(error?.response?.data?.message || "Failed to add employee");
  }
});

export const fetchEmployee = createAsyncThunk(
  "employee/fetchEmployee",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/users", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load employee");
    }
  }
);
export const fetchEmployeeById = createAsyncThunk(
  "employee/fetchEmployeeById",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get(`/users/${data.userId}`, config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load employee");
    }
  }
);

export const deleteEmployee = createAsyncThunk(
  "employee/deleteEmployee",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      console.log({ data });
      const response = await API.delete(
        `/users/delete/${data.id}`,
        { delete_message: data.delete_message },
        config
      );
      data?.setShowDeleteComfirmModal(true);
      showSuccess("Employee deleted!");
      return response.data;
      // return
    } catch (error) {
      throw new Error("Failed to delete employee");
    }
  }
);

export const fetchEmployeeApplications = createAsyncThunk(
  "employee/fetchEmployeeApplications",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get(
        `/applications/user/${data.userId}`,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to load employee");
    }
  }
);

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(addEmployee.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        // console.log({ data: action.meta.arg.userData })
        state.createLoading = false;
        state.message = action.payload?.message;
        state.createError = null;
        // alert(state.message);
      })
      .addCase(adduser.fulfilled, (state, action) => {
        // console.log({ data: action.meta.arg.userData })
        state.createLoading = false;
        state.message = action.payload?.message;
        state.createError = null;
        // alert(state.message);
      })
      .addCase(adduser.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.error?.message;
        state.message = action.error?.message;
        alert(state.createError);
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.error?.message;
        state.message = action.error?.message;
        alert(state.createError);
      })
      .addCase(fetchEmployee.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchEmployee.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.users = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchEmployee.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error?.message;
        state.users = initialState.users;
        state.message = null;
      })
      .addCase(fetchEmployeeById.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchEmployeeById.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.user = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchEmployeeById.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error?.message;
        state.user = initialState.user;
        state.message = null;
      })
      .addCase(fetchEmployeeApplications.pending, (state) => {
        state.applicationsLoading = true;
      })
      .addCase(fetchEmployeeApplications.fulfilled, (state, action) => {
        state.applicationsLoading = false;
        state.applications = action.payload;
        state.fetchError = null;
      })
      .addCase(fetchEmployeeApplications.rejected, (state, action) => {
        state.applicationsLoading = false;
        state.fetchError = action.error?.message;
        state.applications = initialState.applications;
        state.message = null;
      });
  },
});

export default employeeSlice.reducer;
