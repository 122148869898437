// reducers/feedbackSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../helpers/toast";
import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Replace this with your API's base URL

  // Other default configurations (headers, timeout, etc.) can be set here if needed
});
API.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    showError(error.response?.data?.error ?? error.message);
    return Promise.reject(error);
  }
);

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchFeedbacks = createAsyncThunk(
  "fetchFeedbacks",
  async (data) => {
    try {
      const config = {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
          Authorization: `${data.accessToken}`,
        },
      };
      const response = await API.get("/feedbacks", config);
      return response.data;
    } catch (error) {
      throw new Error("Failed to load feedbacks");
    }
  }
);

const feedbackSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeedbacks.pending, (state) => {
        state.fetchLoading = true;
        state.message = "";
      })
      .addCase(fetchFeedbacks.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = null;
        state.data = action.payload;
      })
      .addCase(fetchFeedbacks.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message;
        state.data = null;
        state.message = null;
        state.message = "";
      });
  },
});

export default feedbackSlice.reducer;
